import React from "react";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { storage, db } from "../../../firebase";
import {
  getDocs,
  collection,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";

function EditInventoryItem(props) {
  const [product, setProduct] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState(0);
  const [files, setFiles] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [mainFile, setMainFile] = useState(null);

  // states for each form field
  const [title, setTitle] = useState(null);
  const [sku, setSku] = useState(null);
  const [description, setDescription] = useState(null);
  const [rrp, setRrp] = useState(null);
  const [price, setPrice] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [condition, setCondition] = useState(null);
  const [metal, setMetal] = useState(null);
  const [gem, setGem] = useState("");
  const [brand, setBrand] = useState(null);
  const [jewellery, setJewellery] = useState(null);
  const [size, setSize] = useState("");
  const [cert, setCert] = useState(null);
  const [packaging, setPackaging] = useState(null);
  const [brandRef, setBrandRef] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [available, setAvailable] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      const productData = doc(db, "products", props.match.params.id);
      const data = await getDoc(productData);
      const result = data.data();
      setProduct(result);
      setTitle(result.title);
      setSku(result.sku);
      setDescription(result.description);
      setRrp(result.rrp);
      setPrice(result.price);
      setSalePrice(result.salePrice);
      setCondition(result.condition);
      setMetal(result.metal);
      setGem(result.gem);
      setBrand(result.brand);
      setJewellery(result.jewellery);
      setSize(result.size);
      setPackaging(result.packaging);
      setCert(result.cert);
      setBrandRef(result.brandRef);
      setImageUrls(result.images);
      setMainImage(result.mainImage);
      setFeatured(result.featured);
      setAvailable(result.available);
    };
    getdata();
  }, [props.match.params.id]);

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const updateProduct = async () => {
      const productDoc = doc(db, "products", props.match.params.id);
      const newProduct = {
        title: title,
        sku: sku,
        description: description,
        rrp: rrp,
        price: price,
        salePrice: salePrice,
        condition: condition,
        metal: metal,
        gem: gem,
        brand: brand,
        jewellery: jewellery,
        size: size ? size : " ",
        cert: cert,
        packaging: packaging,
        brandRef: brandRef,
        mainImage: mainImage,
        images: imageUrls,
        featured: featured,
        available: available,
      };
      await updateDoc(productDoc, newProduct);
      setIsSubmit(true);
    };
    updateProduct();

    e.target.reset();
  };

  //Handle Field Changes

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
  };
  const skuChangeHandler = (e) => {
    setSku(e.target.value);
  };
  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };
  const rrpChangeHandler = (e) => {
    setRrp(e.target.value);
  };
  const priceChangeHandler = (e) => {
    setPrice(e.target.value);
  };
  const salePriceChangeHandler = (e) => {
    setSalePrice(e.target.value);
  };
  const conditionChangeHandler = (data) => {
    setCondition(data.value);
  };
  const metalChangeHandler = (e) => {
    setMetal(e.target.value);
  };
  const gemChangeHandler = (e) => {
    setGem(e.target.value);
  };
  const brandChangeHandler = (data) => {
    setBrand(data.value);
  };
  const jewelleryChangeHandler = (data) => {
    setJewellery(data.value);
  };
  const sizeChangeHandler = (e) => {
    setSize(e.target.value);
  };
  const certChangeHandler = (e) => {
    setCert(e.target.value);
  };
  const packagingChangeHandler = (e) => {
    setPackaging(e.target.value);
  };
  const brandRefChangeHandler = (e) => {
    setBrandRef(e.target.value);
  };

  const featuredChangeHandler = (data) => {
    setFeatured(data.value);
  };
  const availableChangeHandler = (data) => {
    setAvailable(data.value);
  };

  // Dropdown Styling

  const borderStyle = {
    control: (styles) => ({ ...styles, borderRadius: "20px" }),
  };

  //Dropdown Options

  const conditionOptions = [
    { value: "Brand New", label: "Brand New" },
    { value: "Ex-Display", label: "Ex-Display" },
    { value: "Unworn", label: "Unworn" },
    { value: "Excellent", label: "Excellent" },
    { value: "Good", label: "Good" },
    { value: "Gently Used", label: "Gently Used" },
  ];

  const featuredOptions = [
    { value: "true", label: "Featured" },
    { value: "false", label: "Not Featured" },
  ];
  const availableOptions = [
    { value: "Is Available", label: "Is Available" },
    { value: "Not Available", label: "Not Available" },
    { value: "Sold", label: "Sold" },
    { value: "Enquire", label: "Enquire" },
  ];

  const [brands, setBrands] = useState(null);
  const [jewelleryList, setJewelleryList] = useState(null);

  const getBrands = async () => {
    const brandsCollectionRef = collection(db, "brands");
    const data = await getDocs(brandsCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setBrands(results);
  };

  const getJewellery = async () => {
    const jewelleryCollectionRef = collection(db, "jewellery");
    const data = await getDocs(jewelleryCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setJewelleryList(results);
  };

  useEffect(() => {
    getBrands();
    getJewellery();
  }, []);

  if (!brands || !jewelleryList || !product) {
    return <h1>Loading...</h1>;
  }

  const brandOptions = [];
  const getBrandOptions = () => {
    brands.forEach((brand) => {
      brandOptions.push({ value: brand.brandName, label: brand.brandName });
    });
  };
  getBrandOptions();

  const jewelleryOptions = [];
  const getJewelleryOptions = () => {
    jewelleryList.forEach((item) => {
      jewelleryOptions.push({
        value: item.jewelleryCategory,
        label: item.jewelleryCategory,
      });
    });
  };
  getJewelleryOptions();

  // handle modal actions;

  const addNew = () => {
    setIsSubmit(false);
  };

  const returnToInventory = () => {
    setIsSubmit(false);
  };

  // Handle Image Uploads

  const uploadMainImage = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/product-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress1(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setMainImage(url);
        });
      }
    );
  };

  const uploadFile = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/product-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrls((prevState) => [...prevState, url]);
        });
      }
    );
  };

  const handleChange = (e) => {
    setFiles(e.target.files);
  };

  const handleMainUpload = () => {
    uploadMainImage(mainFile[0]);
  };

  const handleUpload = async () => {
    setImageUrls([]);
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  };

  const handleMainImageChange = (e) => {
    setMainFile(e.target.files);
    // console.log(e.target.files);
  };

  return (
    <div className="new-product">
      {isSubmit && (
        <div className="modal">
          <div className="modal__container">
            <h3>New Product Added</h3>
            <button type="button" onClick={addNew}>
              Continue Editing
            </button>
            <Link to="/bj-admin/inventory">
              <button type="button" onClick={returnToInventory}>
                Return To Inventory
              </button>
            </Link>
          </div>
        </div>
      )}
      <h1 className="new-product__title">Edit {product.title}</h1>
      <Link to="/bj-admin/inventory">
        <button type="button" onClick={returnToInventory}>
          Return To Inventory
        </button>
      </Link>
      <form className="new-product__form" onSubmit={handleSubmit}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          name="title"
          required
          value={title}
          onChange={titleChangeHandler}
        />
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          required
          value={description}
          onChange={descriptionChangeHandler}
        />
        <label htmlFor="sku">SKU</label>
        <input
          type="text"
          name="sku"
          value={sku}
          onChange={skuChangeHandler}
          required
        />
        <label htmlFor="price">Price</label>
        <input
          type="text"
          name="price"
          value={price}
          onChange={priceChangeHandler}
          required
        />
        <label htmlFor="rrp">RRP Price</label>
        <input type="text" name="rrp" value={rrp} onChange={rrpChangeHandler} />
        <label htmlFor="salePrice">Sale Price</label>
        <input
          type="text"
          name="salePrice"
          value={salePrice}
          onChange={salePriceChangeHandler}
        />
        <label htmlFor="condition">Condition</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={conditionOptions}
          name="condition"
          styles={borderStyle}
          onChange={conditionChangeHandler}
          defaultValue={condition}
          placeholder={condition}
          required
        />
        <label htmlFor="metal">Metal</label>
        <input
          type="text"
          name="metal"
          value={metal}
          onChange={metalChangeHandler}
        />
        <label htmlFor="gem">Gemstone</label>
        <input type="text" name="gem" value={gem} onChange={gemChangeHandler} />
        <label htmlFor="brand">Brand</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={brandOptions}
          name="brand"
          styles={borderStyle}
          onChange={brandChangeHandler}
          defaultValue={brand}
          placeholder={brand}
          required
        />
        <label htmlFor="jewellery">Jewellery Type</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={jewelleryOptions}
          name="jewellery"
          styles={borderStyle}
          onChange={jewelleryChangeHandler}
          defaultValue={jewellery}
          placeholder={jewellery}
          required
        />
        <label htmlFor="size">Size</label>
        <input
          type="text"
          name="size"
          value={size}
          onChange={sizeChangeHandler}
        />
        <label htmlFor="cert">Certification of Authenticity</label>
        <input
          type="text"
          name="cert"
          value={cert}
          onChange={certChangeHandler}
        />
        <label htmlFor="packaging">Packaging</label>
        <input
          type="text"
          name="packaging"
          value={packaging}
          onChange={packagingChangeHandler}
        />
        <label htmlFor="brandRef">Brand Reference</label>
        <input
          type="text"
          name="brandRef"
          id="brandRef"
          value={brandRef}
          onChange={brandRefChangeHandler}
        />
        <div>
          <progress value={progress1} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="mainImage"
            onChange={handleMainImageChange}
            accept="image/*"
          />
          <button onClick={handleMainUpload} type="button">
            Upload
          </button>
          <br />
          <div className="image-container">
            <img
              className="image-container__image"
              style={{ width: "500px" }}
              src={mainImage || "http://via.placeholder.com/300"}
              alt="firebase-prev"
            />
          </div>
        </div>
        <div>
          <progress value={progress} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="images"
            multiple
            onChange={handleChange}
            accept="image/*"
          />
          <button onClick={handleUpload} type="button">
            Upload
          </button>
          <br />
          <br />
          {
            <div className="image-container">
              {imageUrls.map((url, i) => (
                <img
                  className="image-container__image"
                  key={i}
                  style={{ width: "500px" }}
                  src={url || "http://via.placeholder.com/300"}
                  alt="firebase-prev"
                />
              ))}
            </div>
          }
        </div>

        <label htmlFor="featured">Is this a featured item?</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={featuredOptions}
          name="featured"
          onChange={featuredChangeHandler}
          defaultValue={featured}
          placeholder={featured}
          styles={borderStyle}
          required
        />
        <label htmlFor="available">Is this item available</label>

        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={availableOptions}
          name="available"
          styles={borderStyle}
          onChange={availableChangeHandler}
          defaultValue={available}
          placeholder={available}
          required
        />
        <button>Submit Edit</button>
      </form>
    </div>
  );
}

export default EditInventoryItem;
