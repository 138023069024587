import React from "react";
// import Header from "../../assets/images/about-hero.png";
import Subscribe from "../../components/Subscribe/Subscribe";
import "./AboutUs.scss";
function AboutUs() {
  return (
    <div className="about">
      {/* <img
        src={Header}
        alt="Branded Jewellery Header"
        className="about__hero"
      /> */}
      <h1 className="about__title">About Us</h1>
      <p className="about__tagline">
        Branded Jewellery UK has been trading since 2016. As one of London's
        premier pre-owned jewellery dealers, we specialise in the purchase and
        sale of fine new and pre-owned jewellery from the most prestigious
        luxury brands.
      </p>
      <p className="about__main">
        Buyers who are passionate about jewellery from these esteemed brands can
        purchase items at prices that are significantly discounted from the
        original retail price. Branded jewellery, particularly once it has been
        polished and refurbished, can look as good as new, and is therefore
        perfectly placed in this growing trend for sustainable, pre-owned goods.
        Many items offered on this site are current models available with the
        respective retailers, so the savvy shopper can make considerable
        savings.
      </p>
      <p className="about__main">
        Although there are many brands not listed on our website, the chances
        are that we might be able to source the item you are looking for. If you
        have a specific item in mind please contact us.
      </p>
      <p className="about__main">
        Unrivalled customer service is our goal, so if there is anything we are
        able to assist you with, please do get in touch.
      </p>
      <p className="about__main">
        Branded Jewellery UK is based in Hatton Garden, the heart of London's
        Jewellery District. Please contact us for an appointment as we do not
        display our items.
      </p>
      <p className="about__main">Talia Silver-Butters</p>
      <Subscribe />
    </div>
  );
}

export default AboutUs;
