import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import ProductCard from "../../components/ProductCard/ProductCard";

import "./SearchProducts.scss";
import Mail from "../../assets/images/mail.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";

function SearchProducts(props) {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    // Create a RegEx based on the the entire search string (todo: create a regex for each word)
    const regex = new RegExp(props.sort, "gi");

    const getProductData = async () => {
      const productsCollectionRef = collection(db, "products");
      const data = await getDocs(productsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      const filterResults = results.filter((obj) => {
        const filteredProds =
          regex.test(Object.values(obj)) && obj.available === "Is Available";
        return filteredProds;
      });
      // console.log(filterResults);
      setProducts(filterResults);
    };
    getProductData();
  }, [props.sort]);

  if (!products) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="search-products">
      <div className="search-products__container">
        {products.map((product) => {
          return <ProductCard data={product} key={product.id} />;
        })}
      </div>

      {products.length < 1 && (
        <>
          <div className="search-products__none">
            <h2 className="search-products__no-prods">
              We currently have no {props.sort.toLowerCase()} in stock.
              <br />
              Not to worry! We are specialists in finding authentic luxury
              items.
              <br />
            </h2>
            <a
              className="search-products__enquire-container"
              href={`mailto:talia@brandedjewelleryuk.com?subject=${encodeURIComponent(
                props.sort
              )}`}
            >
              <button className="search-products__enquire">
                <img src={Mail} alt="Mail" className="search-products__icon" />{" "}
                Enquire By Email
              </button>
            </a>
            <a
              href="https://wa.me/7949443603"
              className="search-products__enquire-container"
            >
              <button className="search-products__enquire">
                <img
                  src={Whatsapp}
                  alt="whatsapp"
                  className="search-products__icon"
                />
                Enquire By Whatsapp
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default SearchProducts;
