import React, { useState } from "react";
import { db } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { storage } from "../../../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";

function NewBrandForm() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  // upload to firebase

  const uploadFile = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/brand-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrl(url);
        });
      }
    );
  };

  const handleChange = (e) => {
    setFile(e.target.files);
  };

  const handleUpload = () => {
    uploadFile(file[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (imageUrl) {
      const newBrand = {
        // Add key value pairs for new warehouse
        brandName: e.target.brandName.value,
        description: e.target.description.value,
        brandImage: imageUrl,
      };

      const brandsCollectionRef = collection(db, "brands");
      const createNewBrand = async () => {
        await addDoc(brandsCollectionRef, newBrand);
        setIsSubmit(true);
      };
      createNewBrand();
    }

    e.target.reset();

    setImageUrl("");
  };

  // handle model actions;

  const addNew = () => {
    setIsSubmit(false);
  };

  const returnToBrands = () => {
    setIsSubmit(false);
  };

  return (
    <div className="new-product">
      {isSubmit && (
        <div className="modal">
          <div className="modal__container">
            <h3>New Brand Added</h3>
            <button type="button" onClick={addNew}>
              Add Another Brand
            </button>
            <Link to="/bj-admin/brands">
              <button type="button" onClick={returnToBrands}>
                Return To Brands
              </button>
            </Link>
          </div>
        </div>
      )}
      <h1 className="new-product__title">Add New Brand</h1>
      <Link to="/bj-admin/brands">
        <button type="button" onClick={returnToBrands}>
          Return To Brands List
        </button>
      </Link>
      <form className="new-product__form" onSubmit={handleSubmit}>
        <label htmlFor="brandName">Brand Name</label>
        <input type="text" name="brandName" required />
        <label htmlFor="description">Description</label>
        <textarea name="description" required />

        <div>
          <progress value={progress} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="images"
            required
            onChange={handleChange}
            accept="image/*"
          />
          <button onClick={handleUpload} type="button">
            Upload
          </button>
          <br />
          <div className="image-container">
            <img
              className="image-container__image"
              style={{ width: "500px" }}
              src={imageUrl || "http://via.placeholder.com/300"}
              alt="firebase-prev"
            />
          </div>
        </div>

        <button>Add New Brand</button>
      </form>
    </div>
  );
}

export default NewBrandForm;
