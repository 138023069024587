// import axios from "axios";
import React, { useEffect, useState } from "react";
import "./ProductPage.scss";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import Whatsapp from "../../assets/images/whatsapp.svg";
import Mail from "../../assets/images/mail.svg";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

function ProductPage(props) {
  const [data, setData] = useState(null);
  const idUncut = props.match.params.id;
  const id = idUncut.slice(0, idUncut.length - 1);
  // const title = props.match.params.title;

  useEffect(() => {
    const getdata = async () => {
      const productData = doc(db, "products", id);
      const data = await getDoc(productData);
      setData(data.data());
      document.title = data.data().title;
    };

    getdata();
  }, [id]);

  if (!data) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="product-page">
      <div className="product-page__images">
        <ImageCarousel data={data} className="product-page__carousel" />
      </div>
      <div className="product-page__details">
        <h1>{data.title}</h1>
        <p>{data.description}</p>
        <h3 className="product-page__rrp">RRP: £{data.rrp}</h3>
        {data.salePrice && (
          <>
            <h3 className="product-page__sale-price">Was: £{data.price}</h3>
            <h2 className="product-page__price">
              Sale Price: £{data.salePrice}
            </h2>
          </>
        )}
        {!data.salePrice && <h2>Our Price: £{data.price}</h2>}
        <a
          href={`mailto:info@brandedjewelleryuk.com?subject=${encodeURIComponent(
            data.title
          )}`}
        >
          <button className="product-page__enquire">
            <img src={Mail} alt="Mail" className="product-page__icon" /> Enquire
            By Email
          </button>
        </a>
        <a href="https://wa.me/7566209143">
          <button className="product-page__enquire">
            <img src={Whatsapp} alt="whatsapp" className="product-page__icon" />
            Enquire By Whatsapp
          </button>
        </a>

        <div className="product-page__more-details">
          <h4>More Details:</h4>
          <p>Condition: {data.condition}</p>
          <p>Brand: {data.brand}</p>
          <p>{data.size && `Size: ${data.size}`}</p>
          <p>{data.metal && `Metal: ${data.metal}`}</p>
          <p>{data.gemstone && `Gemstone: ${data.gemstone}`}</p>
          <p>{data.brandRef && `Brand Ref: ${data.brandRef}`}</p>
          <p>{data.available && `Availability: ${data.available}`}</p>
          <p>{data.cert && `Certification: ${data.cert}`}</p>
          <p>{data.packaging && `Packaging: ${data.packaging}`}</p>
        </div>
        <h4>SKU: {data.sku}</h4>
      </div>
    </div>
  );
}

export default ProductPage;
