import React from "react";
import { Link } from "react-router-dom";

function JewelleryCategory(props) {
  const item = props.data;

  const setDelete = () => {
    props.setDelete(item.id);
  };

  return (
    <div className="inventory-item" key={item.id}>
      <h3 className="inventory-item__title">{item.jewelleryCategory}</h3>

      <img
        className="inventory-item__img"
        alt="preview-mg"
        src={item.jewelleryImage}
      ></img>

      <Link to={`/bj-admin/jewellery/edit/${item.id}`} item={item}>
        <button className="inventory-item__button">Edit</button>
      </Link>
      <button className="inventory-item__button" onClick={setDelete}>
        Delete
      </button>
    </div>
  );
}

export default JewelleryCategory;
