import React from "react";
import "./MobileNav.scss";
import Menu from "../../../assets/images/menu.svg";
import Search from "../../../assets/images/search.svg";
import RightIcon from "../../../assets/images/right-arrow.svg";
import DropdownMobile from "../DropdownMobile/DropdownMobile";
import { useState } from "react";
import { Link } from "react-router-dom";
import MobLogo from "../../../assets/images/MobLogo.jpg";
import SearchBar from "../../SearchBar/SearchBar";

function MobileNav(props) {
  // States
  const [displayMenu, setDisplayMenu] = useState("--hide");
  const [displayBrands, setDisplayBrands] = useState(false);
  const [displayJewellery, setDisplayJewellery] = useState(false);
  const [displaySearch, setDisplaySearch] = useState("--hide");
  // Event handlers

  const handleMenuClick = () => {
    if (displayMenu === "--hide") {
      setDisplayMenu("--show");
    } else {
      setDisplayMenu("--hide");
    }
    setDisplaySearch("--hide");
  };

  const handleBrandClick = () => {
    if (!displayBrands) {
      setDisplayBrands(true);
    } else {
      setDisplayBrands(false);
    }
  };

  const handleJewelleryClick = () => {
    if (!displayJewellery) {
      setDisplayJewellery(true);
    } else {
      setDisplayJewellery(false);
    }
  };

  const onSearch = (e) => {
    if (displaySearch === "--hide") {
      setDisplaySearch("--show");
    } else {
      setDisplaySearch("--hide");
    }
    setDisplayMenu("--hide");
  };

  return (
    <nav className="navbar">
      <div className="navbar__header">
        <img
          src={Menu}
          alt="menu-icon"
          className="navbar__header-icon"
          onClick={handleMenuClick}
        />
        <Link to="/">
          <img src={MobLogo} alt="logo" className="navbar__logo" />
        </Link>
        <img
          src={Search}
          alt="search-icon"
          className="navbar__header-icon"
          onClick={onSearch}
        />
      </div>
      <SearchBar display={displaySearch} setDisplay={setDisplaySearch} />
      <div className={`navbar__menu${displayMenu}`}>
        <Link to="/shop">
          <div className="navbar__menu-link" onClick={handleMenuClick}>
            <h3>Shop All</h3>
          </div>
        </Link>
        <div className="navbar__menu-link" onClick={handleMenuClick}>
          <Link to="/sale">
            <h3>On Sale</h3>
          </Link>
        </div>
        <div className="navbar__menu-link" onClick={handleBrandClick}>
          <h3>Brands</h3>
          <img src={RightIcon} alt="right-icon" className="navbar__chevron" />
        </div>
        <DropdownMobile
          link="Brands"
          display={displayBrands}
          brands={props.brands}
          jewellery={props.jewellery}
          clickHandler={handleMenuClick}
        />

        <div className="navbar__menu-link" onClick={handleJewelleryClick}>
          <h3>Jewellery</h3>
          <img src={RightIcon} alt="right-icon" className="navbar__chevron" />
        </div>
        <DropdownMobile
          link="Jewellery"
          display={displayJewellery}
          brands={props.brands}
          jewellery={props.jewellery}
          clickHandler={handleMenuClick}
        />
        <Link to="/about-us">
          <div className="navbar__menu-link" onClick={handleMenuClick}>
            <h3>About Us</h3>
          </div>
        </Link>
        <Link to="/contact">
          <div className="navbar__menu-link" onClick={handleMenuClick}>
            <h3>Contact</h3>
          </div>
        </Link>

        <Link to="/sell-yours">
          <div className="navbar__menu-link" onClick={handleMenuClick}>
            <h3>Sell Yours</h3>
          </div>
        </Link>
      </div>
    </nav>
  );
}

export default MobileNav;
