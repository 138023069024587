import React from "react";
import { Link } from "react-router-dom";
import "./InventoryItem.scss";

function InventoryItem(props) {
  const item = props.data;

  const setDelete = () => {
    props.setDelete(item.id);
  };

  return (
    <div className="inventory-item">
      <h3 className="inventory-item__title">{item.title}</h3>
      {item.images.length > 0 && (
        <img
          className="inventory-item__img"
          alt="preview-mg"
          src={item.mainImage}
        ></img>
      )}
      <h3 className="inventory-item__sku">{item.sku}</h3>
      <h4 className="inventory-item__date">{item.timestamp}</h4>
      <Link to={`/bj-admin/inventory/edit/${item.id}`} item={item}>
        <button className="inventory-item__button">Edit</button>
      </Link>
      <button className="inventory-item__button" onClick={setDelete}>
        Delete
      </button>
    </div>
  );
}

export default InventoryItem;
