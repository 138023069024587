import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import ProductCard from "../ProductCard/ProductCard";
import "./SortedProducts.scss";
import Mail from "../../assets/images/mail.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";

function SortedProducts(props) {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    const productsCollectionRef = collection(db, "products");
    const getProductData = async () => {
      const data = await getDocs(productsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const filterResults = results.filter((obj) => {
        return (
          Object.values(obj).includes(props.sort) &&
          obj.available === "Is Available"
        );
      });
      setProducts(filterResults);
      document.title = "Branded Jewellery UK " + props.sort;
    };
    getProductData();
  }, [props.sort]);

  if (!products) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="products">
      {products.length > 0 && <h1 className="products__title">{props.sort}</h1>}
      <div className="products__container">
        {products.map((product) => {
          return <ProductCard data={product} key={product.id} />;
        })}
      </div>

      {products.length < 1 && (
        <>
          <div className="products__none">
            <h2 className="products__no-prods">
              We currently have no {props.sort.toLowerCase()} in stock.
              <br />
              Not to worry! We are specialists in finding authentic luxury
              items.
              <br />
            </h2>
            <a
              className="products__enquire-container"
              href={`mailto:talia@brandedjewelleryuk.com?subject=${encodeURIComponent(
                props.sort
              )}`}
            >
              <button className="products__enquire">
                <img src={Mail} alt="Mail" className="products__icon" /> Enquire
                By Email
              </button>
            </a>
            <a
              href="https://wa.me/7949443603"
              className="products__enquire-container"
            >
              <button className="products__enquire">
                <img src={Whatsapp} alt="whatsapp" className="products__icon" />
                Enquire By Whatsapp
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default SortedProducts;
