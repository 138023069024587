import React from "react";
import "./ProductCard.scss";
// import { useState } from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ data, isOnSale }) => {
  const {
    mainImage,
    title,
    metal,
    gemstone,
    price,
    rrp,
    salePrice,
    id,
    brand,
    jewellery,
  } = data;

  return (
    <>
      <Link
        className="product"
        to={`/shop/${brand}/${jewellery}/product/${id}}`}
      >
        <img
          src={(mainImage && mainImage) || "https://picsum.photos/200"}
          alt="product"
          className="product__image"
        />
        <h4 className="product__title">{title}</h4>

        {!gemstone && <p className="product__metal">{metal}</p>}
        {gemstone && <p className="product__gem">{`${metal}, ${gemstone}`}</p>}
        <h4 className="home-product__price">
          {isOnSale ? ` Original Price: £${price}` : `Our Price: £${price}`}
        </h4>
        {salePrice && (
          <h4 className="product__sale-price">{`Sale Price: £${salePrice}`}</h4>
        )}
        {rrp && <h4 className="product__rrp">{`Retail Price: £${rrp}`}</h4>}
        <button className="product__button">Shop Now</button>
      </Link>
    </>
  );
};

export default ProductCard;
