import React, { useEffect, useState } from "react";
import DesktopNav from "./DesktopNav/DesktopNav";
import MobileNav from "./MobileNav/MobileNav";
import "./Header.scss";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

function Header() {
  const [brands, setBrands] = useState(null);
  const [jewellery, setJewellery] = useState(null);
  const brandsCollectionRef = collection(db, "brands");
  const jewelleryCollectionRef = collection(db, "jewellery");

  const getBrands = async () => {
    const data = await getDocs(brandsCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setBrands(results);
  };

  const getJewellery = async () => {
    const data = await getDocs(jewelleryCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setJewellery(results);
  };

  useEffect(() => {
    getBrands();
    getJewellery();
    // eslint-disable-next-line
  }, []);

  if (!brands || !jewellery) {
    return <h1>Loading...</h1>;
  }

  return (
    <nav>
      <DesktopNav brands={brands} jewellery={jewellery} />
      <MobileNav brands={brands} jewellery={jewellery} />
    </nav>
  );
}

export default Header;
