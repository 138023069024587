import React, { useState } from "react";
import Slider from "react-slick";
import HomeProductCard from "../HomeProductCard/HomeProductCard";
import NextArrow from "../NextArrow/NextArrow";
import PrevArrow from "../PrevArrow/PrevArrow";

import "./FavouriteProducts.scss";

function FavouriteProducts(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setScreenWidth(document.body.clientWidth);
  });

  const dynamicSlides = () => {
    if (screenWidth < 769) {
      return 1;
    } else if (screenWidth < 1284 && screenWidth > 768) {
      return 3;
    } else {
      return 4;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: dynamicSlides(),
    slidesToScroll: dynamicSlides(),
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <>
      <h2 className="favourites__header">Featured Items</h2>
      <Slider {...settings} className="favourites">
        {props.data
          .filter(
            (item) =>
              item.featured === "true" && item.available === "Is Available"
          )
          .map((item) => {
            return <HomeProductCard data={item} key={item.id} />;
          })}
      </Slider>
    </>
  );
}

export default FavouriteProducts;
