import React from "react";
import "./DropdownItem.scss";
import { Link } from "react-router-dom";

function DropdownItem(props) {
  return (
    <Link to={`/shop/${props.name}`} className="dropdown-item">
      <img
        src={props.image}
        alt={props.name}
        className="dropdown-item__image"
      />
      <h3 onClick={props.clickHandler} className="dropdown-item__item">
        {props.name}
      </h3>
    </Link>
  );
}

export default DropdownItem;
