import React from "react";
import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import Email from "../../assets/images/mail.svg";
import Phone from "../../assets/images/phone.svg";
import Logo from "../../assets/images/logo.jpg";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__quicklinks">
          <h3 className="footer__section-title">Quick Links</h3>
          <div className="footer__links">
            <div className="footer__links-container">
              <Link to="/">
                <h4>Home</h4>
              </Link>
              <Link to="/">
                <h4>Shop</h4>
              </Link>
              <Link to="/contact">
                <h4>Contact Us</h4>
              </Link>
              <Link to="/about-us">
                <h4>About Us</h4>
              </Link>
              <Link to="sell-yours">
                <h4>Sell Yours</h4>
              </Link>
            </div>
            <div className="footer__links-container">
              <Link to="/payments">
                <h4>Payments, Delivery & Returns</h4>
              </Link>
              <Link to="/contact">
                <h4>Appointments </h4>
              </Link>
              <Link to="/payments">
                <h4>100% Authenticity Guaranteed</h4>
              </Link>
              <Link to="/terms&conditions">
                <h4>Terms & Conditions</h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer__contacts">
          <h3 className="footer__section-title">Contact</h3>
          <div className="footer__contact-container">
            <img src={Phone} alt="phone" className="footer__icons" />
            <a href="tel:447566 209143">
              <h4>+447566209143</h4>
            </a>
          </div>
          <div className="footer__contact-container">
            <img src={Email} alt="email" className="footer__icons" />
            <a href="mailto:info@brandedjewelleryuk.com">
              <h4>info@brandedjewelleryuk.com</h4>
            </a>
          </div>
          <div className="footer__contact-container">
            <img src={Email} alt="email" className="footer__icons" />
            <div>
              <h4>88-90 Hatton Garden</h4>
              <h4>London EC1N 8PN</h4>
              <h4>(By appointment only)</h4>
            </div>
          </div>
        </div>
        <div className="footer__socials">
          <h3 className="footer__section-title">Join On Social</h3>
          <a
            href="https://www.facebook.com/brandedjewelleryuk"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="Facebook" className="footer__icons" />
          </a>
          <a
            href="https://www.instagram.com/brandedjewelleryuk/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="Instagram" className="footer__icons" />
          </a>
        </div>
        <div className="footer__rights-reserved">
          <h4>@2016 Branded Jewellery UK Limited</h4>
          <h4>VAT Registration Number. 302976012</h4>
          <h4>Company Number:09410866</h4>
          <h4>All Rights Reserved</h4>
        </div>
      </div>
      <img src={Logo} alt="Branded Jewellery Uk" className="footer__logo" />
    </div>
  );
}

export default Footer;
