import React, { useState } from "react";
import Slider from "react-slick";
import SaleProductCard from "../SaleProductCard/SaleProductCard";
import NextArrow from "../NextArrow/NextArrow";
import PrevArrow from "../PrevArrow/PrevArrow";

import "./SaleProducts.scss";

function SaleProducts(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setScreenWidth(document.body.clientWidth);
  });

  const dynamicSlides = () => {
    if (screenWidth < 769) {
      return 1;
    } else if (screenWidth < 1284 && screenWidth > 768) {
      return 3;
    } else {
      return 4;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: dynamicSlides(),
    slidesToScroll: dynamicSlides(),
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const items = props.data.filter(
    (item) => item.salePrice && item.available === "Is Available"
  );

  return (
    <>
      <h2 className="favourites__header">On Sale</h2>
      <Slider {...settings} className="favourites">
        {items.map((item) => {
          return <SaleProductCard data={item} key={item.id} />;
        })}
      </Slider>
    </>
  );
}

export default SaleProducts;
