import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import "./NewProductForm.scss";
import { db, storage } from "../../../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { addDoc, collection, getDocs } from "firebase/firestore";

function NewProductForm() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState(0);
  const [files, setFiles] = useState(null);
  const [mainFile, setMainFile] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [mainImage, setMainImage] = useState("");

  // upload to firebase

  const uploadMainImage = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/product-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress1(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setMainImage(url);
        });
      }
    );
  };

  const uploadFile = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/product-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrls((prevState) => [...prevState, url]);
        });
      }
    );
  };

  const handleChange = (e) => {
    setFiles(e.target.files);
  };

  const handleMainImageChange = (e) => {
    setMainFile(e.target.files);
    // console.log(e.target.files);
  };

  const handleUpload = () => {
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  };

  const handleMainUpload = () => {
    uploadMainImage(mainFile[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageUrls) {
      const newProduct = {
        // Add key value pairs for new warehouse
        title: e.target.title.value,
        sku: e.target.sku.value,
        description: e.target.description.value,
        rrp: e.target.rrp.value,
        price: e.target.price.value,
        salePrice: e.target.salePrice.value,
        condition: e.target.condition.value,
        metal: e.target.metal.value,
        gem: e.target.gem.value || "",
        brand: e.target.brand.value,
        jewellery: e.target.jewellery.value,
        size: e.target.size.value ? e.target.size.value : "",
        brandRef: e.target.brandRef.value,
        mainImage: mainImage,
        images: imageUrls,
        featured: e.target.featured.value,
        available: e.target.available.value,
      };
      const productsCollectionRef = collection(db, "products");
      const createNewProduct = async () => {
        await addDoc(productsCollectionRef, newProduct);
        setIsSubmit(true);
      };
      createNewProduct();
    }

    e.target.reset();

    setImageUrls([]);
  };

  // handle model actions;

  const addNew = () => {
    setIsSubmit(false);
  };

  const returnToInventory = () => {
    setIsSubmit(false);
  };

  // Dropdown Styling

  const borderStyle = {
    control: (styles) => ({ ...styles, borderRadius: "20px" }),
  };

  //Dropdown Options

  const conditionOptions = [
    { value: "Brand New", label: "Brand New" },
    { value: "Ex-Display", label: "Ex-Display" },
    { value: "Unworn", label: "Unworn" },
    { value: "Excellent", label: "Excellent" },
    { value: "Good", label: "Good" },
    { value: "Gently Used", label: "Gently Used" },
  ];

  const featuredOptions = [
    { value: "true", label: "Featured" },
    { value: "false", label: "Not Featured" },
  ];
  const availableOptions = [
    { value: "Is Available", label: "Is Available" },
    { value: "Not Available", label: "Not Available" },
    { value: "Sold", label: "Sold" },
    { value: "Enquire", label: "Enquire" },
  ];

  const [brands, setBrands] = useState(null);
  const [jewelleryList, setJewelleryList] = useState(null);

  const getBrands = async () => {
    const brandsCollectionRef = collection(db, "brands");
    const data = await getDocs(brandsCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setBrands(results);
  };

  const getJewellery = async () => {
    const jewelleryCollectionRef = collection(db, "jewellery");
    const data = await getDocs(jewelleryCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setJewelleryList(results);
  };

  useEffect(() => {
    getBrands();
    getJewellery();
  }, []);

  if (!brands || !jewelleryList) {
    return <h1>Loading...</h1>;
  }

  const brandOptions = [];
  const getBrandOptions = () => {
    brands.forEach((brand) => {
      brandOptions.push({ value: brand.brandName, label: brand.brandName });
    });
  };
  getBrandOptions();

  const jewelleryOptions = [];
  const getJewelleryOptions = () => {
    jewelleryList.forEach((item) => {
      jewelleryOptions.push({
        value: item.jewelleryCategory,
        label: item.jewelleryCategory,
      });
    });
  };
  getJewelleryOptions();

  return (
    <div className="new-product">
      {isSubmit && (
        <div className="modal">
          <div className="modal__container">
            <h3>New Product Added</h3>
            <button type="button" onClick={addNew}>
              Add Another Product
            </button>
            <Link to="/bj-admin/inventory">
              <button type="button" onClick={returnToInventory}>
                Return To Inventory
              </button>
            </Link>
          </div>
        </div>
      )}
      <h1 className="new-product__title">Add New Product</h1>
      <Link to="/bj-admin/inventory">
        <button type="button" onClick={returnToInventory}>
          Return To Inventory
        </button>
      </Link>
      <form className="new-product__form" onSubmit={handleSubmit}>
        <label htmlFor="title">Title</label>
        <input type="text" name="title" required />
        <label htmlFor="description">Description</label>
        <textarea name="description" required />
        <label htmlFor="sku">SKU</label>
        <input type="text" name="sku" required />
        <label htmlFor="price">Price</label>
        <input type="text" name="price" required />
        <label htmlFor="rrp">RRP Price</label>
        <input type="text" name="rrp" />
        <label htmlFor="salePrice">Sale Price</label>
        <input type="text" name="salePrice" />
        <label htmlFor="condition">Condition</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={conditionOptions}
          name="condition"
          styles={borderStyle}
          required
        />
        <label htmlFor="metal">Metal</label>
        <input type="text" name="metal" />
        <label htmlFor="gem">Gemstone</label>
        <input type="text" name="gem" />
        <label htmlFor="brand">Brand</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={brandOptions}
          name="brand"
          styles={borderStyle}
          required
        />
        <label htmlFor="jewellery">Jewellery Type</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={jewelleryOptions}
          name="jewellery"
          styles={borderStyle}
          required
        />
        <label htmlFor="size">Size</label>
        <input type="text" name="size" defaultValue="" />
        <label htmlFor="cert">Certification</label>
        <input type="text" name="cert" />
        <label htmlFor="packaging">Packaging</label>
        <input type="text" name="packaging" />
        <label htmlFor="brandRef">Brand Reference</label>
        <input type="text" name="brandRef" id="brandRef" />

        <div>
          <progress value={progress1} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="mainImage"
            required
            onChange={handleMainImageChange}
            accept="image/*"
          />
          <button onClick={handleMainUpload} type="button">
            Upload
          </button>
          <br />
          <div className="image-container">
            <img
              className="image-container__image"
              style={{ width: "500px" }}
              src={mainImage || "http://via.placeholder.com/300"}
              alt="firebase-prev"
            />
          </div>
        </div>
        <div>
          <progress value={progress} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="images"
            required
            multiple
            onChange={handleChange}
            accept="image/*"
          />
          <button onClick={handleUpload} type="button">
            Upload
          </button>
          <br />
          <div className="image-container">
            {imageUrls.map((url, i) => (
              <img
                className="image-container__image"
                key={i}
                style={{ width: "500px" }}
                src={url || "http://via.placeholder.com/300"}
                alt="firebase-prev"
              />
            ))}
          </div>
        </div>

        <label htmlFor="featured">Is this a featured item?</label>
        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={featuredOptions}
          name="featured"
          styles={borderStyle}
          required
        />
        <label htmlFor="available">Is this item available</label>

        <Select
          className="new-inv__dropdown new-inv__dropdown1"
          options={availableOptions}
          name="available"
          styles={borderStyle}
          required
        />
        <button>Add New Product</button>
      </form>
    </div>
  );
}

export default NewProductForm;
