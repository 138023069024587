import React from "react";
import "./NextArrow.scss";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",

        right: "-10px",
        zIndex: "20",
      }}
      onClick={onClick}
    />
  );
}

export default NextArrow;
