import React from "react";
import DropdownItem from "../DropdownItem/DropdownItem";
// import { useState } from "react";
import "./Dropdown.scss";
import Slider from "react-slick";
import NextArrow from "../../HomeComponents/NextArrow/NextArrow";
import PrevArrow from "../../HomeComponents/PrevArrow/PrevArrow";

function Dropdown(props) {
  const onHover = (e) => {
    props.setDisplay("--show");
  };

  const offHover = (e) => {
    props.setDisplay("--hide");
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      className={`dropdown dropdown${props.display}`}
      onMouseOver={onHover}
      onMouseOut={offHover}
    >
      <div className="dropdown__container">
        <Slider {...settings} className="dropdown__slider">
          {props.link === "Brands" &&
            props.brands.map((brand) => {
              return (
                <DropdownItem
                  name={brand.brandName}
                  key={brand.id}
                  image={brand.brandImage}
                />
              );
            })}

          {props.link === "Jewellery" &&
            props.jewellery.map((jewl) => {
              return (
                <DropdownItem
                  name={jewl.jewelleryCategory}
                  key={jewl.id}
                  image={jewl.jewelleryImage}
                />
              );
            })}
        </Slider>
      </div>
    </div>
  );
}

export default Dropdown;
