import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import BrandItem from "./BrandItem/BrandItem";

function BrandCategories() {
  const [brands, setBrands] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      const brandsCollectionRef = collection(db, "brands");
      const data = await getDocs(brandsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setBrands(results.reverse());
    };
    getdata();
  }, []);

  const handleDelete = async () => {
    // axios
    //   .delete(`${process.env.REACT_APP_URL}/brands/${deleteItem}`)
    //   .then((response) => {
    //     console.log("delete success", response);
    //     setBrands((prev) => prev.filter((brand) => brand.id !== deleteItem));
    //     setDeleteItem(null);
    //   })
    //   .catch((error) => console.log("delete error", error));

    const brandDoc = doc(db, "brands", deleteItem);
    await deleteDoc(brandDoc);
    setDeleteItem(null);
    const brandsCollectionRef = collection(db, "brands");
    const data = await getDocs(brandsCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setBrands(results);
  };

  const handleCancel = () => {
    setDeleteItem(null);
  };

  if (!brands) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <Link to="/bj-admin">
        <button className="navb">Admin Home</button>
      </Link>
      <Link to="brands/new-brand">
        <button className="navb">Add New Brand</button>
      </Link>

      {brands.map((item) => {
        return (
          <div key={item.id}>
            <BrandItem data={item} key={item.id} setDelete={setDeleteItem} />
            {deleteItem === item.id && (
              <div className="delete-modal">
                <h3>Are you sure you want to delete this item?</h3>
                <button type="button" onClick={handleDelete}>
                  Delete
                </button>
                <button type="button" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default BrandCategories;
