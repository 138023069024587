import React from "react";
import Slider from "react-slick";
import Header1 from "../../../assets/images/header1.png";
// import Header2 from "../../../assets/images/header2.png";
// import Header3 from "../../../assets/images/header3.png";
// import Sale from "../../../assets/images/sale.png";
// import Necklace from "../../../assets/images/necklaces.png";
// import Earrings from "../../../assets/images/earrings.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Hero.scss";

function Hero() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    arrows: false,
    centerPadding: 0,
    autoplaySpeed: 5000,
  };
  return (
    <>
      <Slider {...settings} className="slider">
        <div className="slider__container" key="1">
          <img src={Header1} alt="header" className="slider__img" />
        </div>
        {/* <div className="slider__container">
          <img
            src={Header2}
            alt="necklace-hero"
            className="slider__img"
            key="2"
          />
        </div> */}
        {/* <div className="slider__container">
          <img src={Header3} alt="ring-hero" className="slider__img" key="3" />
        </div> */}
      </Slider>
    </>
  );
}

export default Hero;
