import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import JewelleryCategory from "./JewelleryCategory/JewelleryCategory";

function JewelleryCategories() {
  const [categories, setCategories] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      const jewelleryCollectionRef = collection(db, "jewellery");
      const data = await getDocs(jewelleryCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setCategories(results.reverse());
    };
    getdata();
  }, []);

  const handleDelete = async () => {
    const jewelleryDoc = doc(db, "jewellery", deleteItem);
    await deleteDoc(jewelleryDoc);
    setDeleteItem(null);
    const jewellerysCollectionRef = collection(db, "jewellery");
    const data = await getDocs(jewellerysCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setCategories(results);
  };

  const handleCancel = () => {
    setDeleteItem(null);
  };

  if (!categories) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <Link to="/bj-admin">
        <button className="navb">Admin Home</button>
      </Link>
      <Link to="jewellery/new-category">
        <button className="navb">Add New Category</button>
      </Link>

      {categories.map((item) => {
        return (
          <div key={item.id}>
            <JewelleryCategory
              data={item}
              key={item.id}
              setDelete={setDeleteItem}
            />
            {deleteItem === item.id && (
              <div className="delete-modal">
                <h3>Are you sure you want to delete this item?</h3>
                <button type="button" onClick={handleDelete}>
                  Delete
                </button>
                <button type="button" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default JewelleryCategories;
