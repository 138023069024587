import "./SearchBar.scss";
import { withRouter } from "react-router-dom";

function SearchBar(props) {
  const submitHandler = (event) => {
    event.preventDefault();

    props.history.push(
      `/search/${encodeURIComponent(event.target.search.value.toLowerCase())}`
    );
    props.setDisplay("--hide");
    event.target.reset();
  };

  // console.log(props);
  return (
    <div className={`searchbar${props.display}`}>
      <form className="searchbar__form" onSubmit={submitHandler}>
        <input
          name="search"
          type="text"
          className="searchbar__textbox"
          placeholder="Search The Collection"
        />
      </form>
    </div>
  );
}

export default withRouter(SearchBar);
