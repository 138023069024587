import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import SearchBar from "../../SearchBar/SearchBar";
import Email from "../../../assets/images/mail.svg";
import Phone from "../../../assets/images/phone.svg";
import SideLogo from "../../../assets/images/side-logo.jpg";

import "./DesktopNav.scss";
import Logo from "../../../assets/images/logo.jpg";
import Search from "../../../assets/images/search.svg";
import { Link } from "react-router-dom";

function DesktopNav(props) {
  const [displayDropdown, setDisplayDropdown] = useState("--hide");
  const [displaySearch, setDisplaySearch] = useState("--hide");
  const [linkOver, setLinkOver] = useState("");

  const onHover = (e) => {
    setDisplayDropdown("--show");
    setLinkOver(e.target.innerText);
    setDisplaySearch("--hide");
  };

  const offHover = (e) => {
    setDisplayDropdown("--hide");
  };

  const onSearch = () => {
    if (displaySearch === "--hide") {
      setDisplaySearch("--show");
    } else {
      setDisplaySearch("--hide");
    }
  };

  return (
    <nav className="desknav">
      <div className="desknav__upper">
        <div className="desknav__side-container">
          <Link to="/">
            <img
              src={SideLogo}
              alt="side logo"
              className="desknav__side-logo"
            />
          </Link>
        </div>

        <Link to="/">
          <img src={Logo} alt="logo" className="desknav__logo" />
        </Link>
        <div className="desknav__contacts">
          <div className="desknav__contact-container">
            <img src={Phone} alt="phone" className="desknav__icons" />
            <a href="tel:447566 209143">
              <h4>+447566209143</h4>
            </a>
          </div>
          <div className="desknav__contact-container">
            <img src={Email} alt="email" className="desknav__icons" />
            <a href="mailto:info@brandedjewelleryuk.com">
              <h4>info@brandedjewelleryuk.com</h4>
            </a>
          </div>
        </div>
      </div>

      <ul className="desknav__links">
        <li className="desknav__link">
          <Link to="/shop">
            <h4>Shop All</h4>
          </Link>
        </li>
        <li className="desknav__link">
          <Link to="/sale">
            <h4>On Sale</h4>
          </Link>
        </li>
        <li
          className="desknav__link"
          onMouseOver={onHover}
          onMouseOut={offHover}
        >
          <h4>Brands</h4>
        </li>

        <li
          className="desknav__link"
          onMouseOver={onHover}
          onMouseOut={offHover}
        >
          <h4>Jewellery</h4>
        </li>
        <li className="desknav__link">
          <Link to="/about-us">
            <h4>About Us</h4>
          </Link>
        </li>
        <li className="desknav__link">
          <Link to="/contact">
            <h4>Contact</h4>
          </Link>
        </li>
        <li className="desknav__link">
          <Link to="/sell-yours">
            <h4>Sell Yours</h4>
          </Link>
        </li>
        <img
          className="desknav__search-icon"
          src={Search}
          alt="search"
          onClick={onSearch}
        />
      </ul>
      <Dropdown
        display={displayDropdown}
        setDisplay={setDisplayDropdown}
        link={linkOver}
        brands={props.brands}
        jewellery={props.jewellery}
      />
      <SearchBar display={displaySearch} setDisplay={setDisplaySearch} />
    </nav>
  );
}

export default DesktopNav;
