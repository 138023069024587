import React from "react";
// import Header from "../../assets/images/about-hero.png";
import Subscribe from "../../components/Subscribe/Subscribe";
import Phone from "../../assets/images/phone.svg";
import Mail from "../../assets/images/mail.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";
import "./Contact.scss";
function Contact() {
  return (
    <div className="contact">
      {/* <img
        src={Header}
        alt="Branded Jewellery Header"
        className="contact__hero"
      /> */}
      <h1 className="contact__title">Contact</h1>
      <p className="contact__tagline">
        At Branded Jewellery UK we're always on hand to help with any enquiries.
        We can be reached by any of the following methods.
      </p>
      <div className="contact-container">
        <a href="tel:7566209143">
          <div className="contact-box">
            <h2 className="contact-box__title">Phone</h2>
            <img src={Phone} alt="phone" className="contact-box__image" />
            <h4 className="contact-box__contact">+44 7566 209143</h4>
          </div>
        </a>
        <a href="https://wa.me/7566209143">
          <div className="contact-box">
            <h2 className="contact-box__title">WhatsApp</h2>
            <img src={Whatsapp} alt="phone" className="contact-box__image" />
            <h4 className="contact-box__contact">+44 7566 209143</h4>
          </div>
        </a>
        <a href="mailto:info@brandedjewelleryuk.com">
          <div className="contact-box">
            <h2 className="contact-box__title">E-mail</h2>
            <img src={Mail} alt="phone" className="contact-box__image" />
            <h4 className="contact-box__contact">
              info@brandedjewelleryuk.com
            </h4>
          </div>
        </a>
      </div>

      <Subscribe />
    </div>
  );
}

export default Contact;
