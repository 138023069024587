import React, { useState } from "react";
import "./Admin.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

function Admin() {
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <div className="admin">
      <h1>Welcome to the admin pannel</h1>
      <div className="admin__buttons">
        <Link to="/bj-admin/inventory">
          <button>Inventory</button>
        </Link>
        <Link to="bj-admin/jewellery">
          <button>Jewellery Categories</button>
        </Link>
        <Link to="bj-admin/brand-category">
          <button>Brand Categories</button>
        </Link>
        <button onClick={handleLogout}>Log Out</button>
      </div>
      <p>{error}</p>
    </div>
  );
}

export default Admin;
