import React from "react";
import "./HomeProductCard.scss";
// import { useState } from "react";
import { Link } from "react-router-dom";

function HomeProductCard(props) {
  return (
    <>
      <Link
        className="home-product"
        to={`/shop/${props.data.brand}/${props.data.jewellery}/product/${props.data.id}}`}
      >
        <img
          src={props.data.mainImage || "https://picsum.photos/200"}
          alt="product"
          className="home-product__image"
        />
        <h4 className="home-product__title">{props.data.title}</h4>
        {!props.data.gemstone && (
          <p className="home-product__metal">{props.data.metal}</p>
        )}
        {props.data.gemstone && (
          <p className="home-product__gem">{`${props.data.metal}, ${props.data.gemstone}`}</p>
        )}
        <h4 className="home-product__price">Our Price: £{props.data.price}</h4>
        {props.data.rrp && (
          <h4 className="product__rrp">{`Retail Price: £${props.data.rrp}`}</h4>
        )}
        <button className="home-product__button">Shop Now</button>
      </Link>
    </>
  );
}

export default HomeProductCard;
