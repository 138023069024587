import { db } from "../../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { storage } from "../../../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";

function EditJewelleryItem(props) {
  const [category, setCategory] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  // states for each form field
  const [jewelleryCategory, setJewelleryCategory] = useState(null);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      const jewelleryData = doc(db, "jewellery", props.match.params.id);
      const data = await getDoc(jewelleryData);
      const result = data.data();
      setCategory(result);
      setJewelleryCategory(result.jewelleryCategory);
      setDescription(result.description);
      setImageUrl(result.jewelleryImage);
    };
    getdata();
  }, [props.match.params.id]);

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const jewelleryDoc = doc(db, "jewellery", props.match.params.id);
    const newCategory = {
      jewelleryCategory: jewelleryCategory,
      description: description,
      jewelleryImage: imageUrl,
    };

    await updateDoc(jewelleryDoc, newCategory);
    setIsSubmit(true);

    e.target.reset();
  };

  //Handle Field Changes

  const jewelleryCategoryChangeHandler = (e) => {
    setJewelleryCategory(e.target.value);
  };
  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };

  // handle modal actions;

  const addNew = () => {
    setIsSubmit(false);
  };

  const returnToInventory = () => {
    setIsSubmit(false);
  };

  // Handle Image Uploads

  const uploadFile = (file) => {
    // if file doesnt exist return
    if (!file) return;
    // if file does exist;

    const storageRef = ref(
      storage,
      `/category-images/${file.lastModified}${file.name}`
    );
    let uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrl(url);
        });
      }
    );
  };

  const handleChange = (e) => {
    setFile(e.target.files);
  };

  const handleUpload = async () => {
    setImageUrl("");

    uploadFile(file[0]);
  };

  return (
    <div className="new-product">
      {isSubmit && (
        <div className="modal">
          <div className="modal__container">
            <h3>New Category Added</h3>
            <button type="button" onClick={addNew}>
              Continue Editing
            </button>
            <Link to="/bj-admin/jewellery">
              <button type="button" onClick={returnToInventory}>
                Return To Category List
              </button>
            </Link>
          </div>
        </div>
      )}
      <h1 className="new-product__title">Edit {category.jewelleryCategory}</h1>
      <Link to="/bj-admin/jewellery">
        <button type="button" onClick={returnToInventory}>
          Return To Category List
        </button>
      </Link>
      <form className="new-product__form" onSubmit={handleSubmit}>
        <label htmlFor="jewelleryCategory">Category Name</label>
        <input
          type="text"
          name="jewelleryCategory"
          required
          value={jewelleryCategory}
          onChange={jewelleryCategoryChangeHandler}
        />
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          required
          value={description}
          onChange={descriptionChangeHandler}
        />

        <div>
          <progress value={progress} max="100" />
          <br />
          <br />
          <input
            type="file"
            name="images"
            onChange={handleChange}
            accept="image/*"
          />
          <button onClick={handleUpload} type="button">
            Upload
          </button>
          <br />
          <br />
          {
            <div className="image-container">
              <img
                className="image-container__image"
                style={{ width: "500px" }}
                src={imageUrl || "http://via.placeholder.com/300"}
                alt="firebase-prev"
              />
            </div>
          }
        </div>

        <button>Submit Edit</button>
      </form>
    </div>
  );
}

export default EditJewelleryItem;
