import React from "react";
import Header from "../../assets/images/about-hero.png";
import Subscribe from "../../components/Subscribe/Subscribe";
import { Link } from "react-router-dom";
import "./SellYours.scss";

function SellYours() {
  return (
    <div className="sell-yours">
      <img
        src={Header}
        alt="Branded Jewellery Header"
        className="sell-yours__hero"
      />
      <h1 className="sell-yours__title">SELL YOUR JEWELLERY</h1>
      <main className="sell-yours__content">
        <p className="sell-yours__main">
          We offer an easy, trustworthy and transparent service. For a no
          obligation quote: <br />
          <br /> 1. Contact us via whatsapp or email with information on the
          item you wish to sell. Include as much information as possible
          including any certification and paperwork and high quality images.
          <br />
          <br /> 2. We will analyse the information provided and offer you an
          instant competitive quote. (This is subject to inspection upon
          receipt) <br />
          <br /> 3. Make an appointment or send your jewellery to us. <br />
          <br /> 4. Instant payment <br />
          <br /> CONTACT US NOW FOR AN INSTANT QUOTE
        </p>
        <Link to="/contact" className="sell-yours__contact">
          <button>Contact Us</button>
        </Link>
      </main>
      <Subscribe />
    </div>
  );
}

export default SellYours;
