import React, { useEffect, useState } from "react";
import InventoryItem from "./InventoryItem/InventoryItem";
import { db } from "../../firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Inventory.scss";

function Inventory() {
  const [Inventory, setInventory] = useState([]);
  const [fullInventory, setFullInventory] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      const productsCollectionRef = collection(db, "products");
      const data = await getDocs(productsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setInventory(results.sort((a, b) => (a.title > b.title ? 1 : -1)));
      setFullInventory(results.sort((a, b) => (a.title > b.title ? 1 : -1)));
      document.title = "Branded Jewellery UK";
    };
    getdata();
  }, []);

  const handleDelete = async () => {
    const productDoc = doc(db, "products", deleteItem);
    await deleteDoc(productDoc);
    setDeleteItem(null);
    const productsCollectionRef = collection(db, "products");
    const data = await getDocs(productsCollectionRef);
    let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setInventory(results.sort((a, b) => (a.title > b.title ? 1 : -1)));
    setFullInventory(results.sort((a, b) => (a.title > b.title ? 1 : -1)));
  };

  const handleCancel = () => {
    setDeleteItem(null);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    const regex = new RegExp(e.target.invsearch.value, "gi");
    const filterResults = Inventory.filter((obj) => {
      const filteredProds = regex.test(Object.values(obj));
      return filteredProds;
    });
    // console.log(filterResults);
    setInventory(filterResults);
    e.target.reset();
  };

  const changeHandler = () => {
    setInventory(fullInventory);
  };

  return (
    <div>
      <Link to="/bj-admin">
        <button className="navb">Admin Home</button>
      </Link>
      <Link to="inventory/new-product">
        <button className="navb">Add New Product</button>
      </Link>
      <form onSubmit={searchHandler} className="inv-search">
        <input type="text" name="invsearch" onChange={changeHandler} />
        <button>Search</button>
      </form>
      <div className="header">
        <h3 className="header__title">Title</h3>
        <h3 className="header__img">Image</h3>
        <h3 className="header__sku">SKU</h3>

        <h3 className="header__date">Date Modified</h3>
      </div>
      {Inventory.map((item) => {
        return (
          <div key={item.id}>
            <InventoryItem
              data={item}
              key={item.id}
              setDelete={setDeleteItem}
            />
            {deleteItem === item.id && (
              <div className="delete-modal">
                <h3>Are you sure you want to delete this item?</h3>
                <button type="button" onClick={handleDelete}>
                  Delete
                </button>
                <button type="button" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Inventory;
