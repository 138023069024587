import React from "react";
// import { useState } from "react";
import "./DropdownMobile.scss";
import DropdownItemMobile from "../DropdownItemMobile/DropdownItemMobile";

function DropdownMobile(props) {
  return (
    <div className={`dropdown dropdown${props.display ? "--show" : "--hide"}`}>
      {props.link === "Brands" &&
        props.brands.map((brand) => {
          return (
            <DropdownItemMobile
              name={brand.brandName}
              key={brand.id}
              clickHandler={props.clickHandler}
            />
          );
        })}

      {props.link === "Jewellery" &&
        props.jewellery.map((jewl) => {
          return (
            <DropdownItemMobile
              name={jewl.jewelleryCategory}
              key={jewl.id}
              clickHandler={props.clickHandler}
            />
          );
        })}
    </div>
  );
}

export default DropdownMobile;
