import React from "react";
import Header from "../../assets/images/about-hero.png";
import Subscribe from "../../components/Subscribe/Subscribe";
import "./Terms.scss";

function Terms() {
  return (
    <div className="terms">
      <img
        src={Header}
        alt="Branded Jewellery Header"
        className="terms__hero"
      />
      <h1 className="terms__title">Terms & Conditions</h1>
      <main className="terms__content">
        <p className="terms__main">
          These terms and conditions govern your use of this website; by using
          this website, you accept these terms and conditions in full. If you
          disagree with these terms and conditions or any part of these terms
          and conditions, you must not use this website.
          <br />
          <br />
          You must be at least [18] years of age to use this website. By using
          this website and by agreeing to these terms and conditions you warrant
          and represent that you are at least [18] years of age.
          <br />
          <br />
          This website uses cookies. By using this website and agreeing to these
          terms and conditions, you consent to our ‘Branded Jewellery UK’ use of
          cookies in accordance with the terms of ‘Branded Jewellery UK’ privacy
          policy / cookies policy.
        </p>
        <h3 className="terms__subtitle">Privacy Policy</h3>
        <p className="terms__main">
          We do not store credit card details nor do we share customer details
          with any 3rd parties.
        </p>
        <h3 className="terms__subtitle">License to use website</h3>
        <p className="terms__main">
          Unless otherwise stated, ‘Branded Jewellery UK’ and/or its licensors
          own the intellectual property rights in the website and material on
          the website. Subject to the license below, all these intellectual
          property rights are reserved.
          <br />
          <br />
          You may view, download for caching purposes only, and print pages or
          OTHER CONTENT from the website for your own personal use, subject to
          the restrictions set out below and elsewhere in these terms and
          conditions.
          <br /> <br />
          You must not:
        </p>
        <ul>
          <li>
            republish material from this website (including republication on
            another website);
          </li>
          <li>sell, rent or sub-license material from the website;</li>
          <li>show any material from the website in public;</li>
          <li>
            reproduce, duplicate, copy or otherwise exploit material on this
            website for a commercial purpose;
          </li>
          <li>edit or otherwise modify any material on the website; or</li>
          <li>
            redistribute material from this website [except for content
            specifically and expressly made available for redistribution]
          </li>
        </ul>
        <p>
          Where content is specifically made available for redistribution, it
          may only be redistributed within your organisation.
        </p>
        <h3 className="terms__subtitle">Acceptable use</h3>
        <p className="terms__main">
          You must not use this website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity. <br />
          <br /> You must not use this website to copy, store, host, transmit,
          send, use, publish or distribute any material which consists of (or is
          linked to) any spyware, computer virus, Trojan horse, worm, keystroke
          logger, rootkit or other malicious computer software. You must not
          conduct any systematic or automated data collection activities
          (including without limitation scraping, data mining, data extraction
          and data harvesting) on or in relation to this website without
          ‘Branded Jewellery UK’ express written consent. <br />
          <br /> You must not use this website to transmit or send unsolicited
          commercial communications. <br />
          <br /> You must not use this website for any purposes related to
          marketing without ‘Branded Jewellery UK’ express written consent.
        </p>
        <h3 className="terms__subtitle">Restricted access</h3>
        <p className="terms__main">
          Access to certain areas of this website is restricted. ‘Branded
          Jewellery UK’ reserves the right to restrict access to [other] areas
          of this website, or indeed this entire website, at ‘Branded Jewellery
          UK’ discretion. <br />
          <br /> If ‘Branded Jewellery UK’ provides you with a user ID and
          password to enable you to access restricted areas of this website or
          other content or services, you must ensure that the user ID and
          password are kept confidential. <br />
          <br /> ‘Branded Jewellery UK’ may disable your user ID and password in
          ‘Branded Jewellery UK’ sole discretion without notice or explanation.
        </p>
        <h3 className="terms__subtitle">User content</h3>
        <p className="terms__main">
          In these terms and conditions, “your user content” means material
          (including without limitation text, images, audio material, video
          material and audio-visual material) that you submit to this website,
          for whatever purpose. <br />
          <br /> You grant to ‘Branded Jewellery UK’ a worldwide, irrevocable,
          non-exclusive, royalty-free license to use, reproduce, adapt, publish,
          translate and distribute your user content in any existing or future
          media. You also grant to ‘Branded Jewellery UK’ the right to
          sub-license these rights, and the right to bring an action for
          infringement of these rights. <br />
          <br /> Your user content must not be illegal or unlawful, must not
          infringe any third party’s legal rights, and must not be capable of
          giving rise to legal action whether against you or ‘Branded Jewellery
          UK’ or a third party (in each case under any applicable law). <br />
          <br /> You must not submit any user content to the website that is or
          has ever been the subject of any threatened or actual legal
          proceedings or other similar complaint. <br />
          <br /> ‘Branded Jewellery UK’ reserves the right to edit or remove any
          material submitted to this website, or stored on ‘Branded Jewellery
          UK’ servers, or hosted or published upon this website. <br />
          <br /> Notwithstanding ‘Branded Jewellery UK’ rights under these terms
          and conditions in relation to user content, ‘Branded Jewellery UK’
          does not undertake to monitor the submission of such content to, or
          the publication of such content on, this website.
        </p>
        <h3 className="terms__subtitle">Prices</h3>
        <p className="terms__main">
          All prices are in UK Pounds and are inclusive of VAT where applicable,
          at the current rate. PRE-OWNED jewellery is NOT subject to VAT.
          <br />
          <br /> Nothing on this website constitutes, or is meant to constitute,
          advice of any kind. [If you require advice in relation to any [legal,
          financial or medical] matter you should consult an appropriate
          professional.]
        </p>
        <h3 className="terms__subtitle">Limitations of liability</h3>
        <p className="terms__main">
          ‘Branded Jewellery UK’ will not be liable to you (whether under the
          law of contact, the law of torts or otherwise) in relation to the
          contents of, or use of, or otherwise in connection with, this website:{" "}
          <br />
          <br />
          [to the extent that the website is provided free-of-charge, for any
          direct loss;] <br />
          <br /> – for any indirect, special or consequential loss; or <br />
          <br /> – for any business losses, loss of revenue, income, profits or
          anticipated savings, loss of contracts or business relationships, loss
          of reputation or goodwill, or loss or corruption of information or
          data. <br />
          <br /> These limitations of liability apply even if ‘Branded Jewellery
          UK’ has been expressly advised of the potential loss.
        </p>
        <h3 className="terms__subtitle">Exceptions</h3>
        <p className="terms__main">
          Nothing in this website disclaimer will exclude or limit any warranty
          implied by law that it would be unlawful to exclude or limit; and
          nothing in this website disclaimer will exclude or limit ‘Branded
          Jewellery UK’ liability in respect of any:
          <br />
          <br />
          – death or personal injury caused by ‘Branded Jewellery UK’
          negligence;
          <br />
          <br /> – fraud or fraudulent misrepresentation on the part of ‘Branded
          Jewellery UK’; or
          <br />
          <br /> – matter which it would be illegal or unlawful for ‘Branded
          Jewellery UK’ to exclude or limit, or to attempt or purport to exclude
          or limit, its liability.
        </p>
        <h3 className="terms__subtitle">Reasonableness</h3>
        <p className="terms__main">
          By using this website, you agree that the exclusions and limitations
          of liability set out in this website disclaimer are reasonable. If you
          do not think they are reasonable, you must not use this website.
        </p>
        <h3 className="terms__subtitle">Other parties</h3>
        <p className="terms__main">
          You accept that, as a limited liability entity, ‘Branded Jewellery UK’
          has an interest in limiting the personal liability of its officers and
          employees. You agree that you will not bring any claim personally
          against ‘Branded Jewellery UK’ officers or employees in respect of any
          losses you suffer in connection with the website. <br />
          <br /> Without prejudice to the foregoing paragraph, you agree that
          the limitations of warranties and liability set out in this website
          disclaimer will protect ‘Branded Jewellery UK’ officers, employees,
          agents, subsidiaries, successors, assigns and sub-contractors as well
          as‘Branded Jewellery UK’.
        </p>
        <h3 className="terms__subtitle">Unenforceable provisions</h3>
        <p className="terms__main">
          If any provision of this website disclaimer is, or is found to be,
          unenforceable under applicable law, that will not affect the
          enforceability of the other provisions of this website disclaimer.
        </p>
        <h3 className="terms__subtitle">Indemnity</h3>
        <p className="terms__main">
          You hereby indemnify ‘Branded Jewellery UK’ and undertake to keep
          ‘Branded Jewellery UK’ indemnified against any losses, damages, costs,
          liabilities and expenses (including without limitation legal expenses
          and any amounts paid by ‘Branded Jewellery UK’ to a third party in
          settlement of a claim or dispute on the advice of ‘Branded Jewellery
          UK’ legal advisers) incurred or suffered by ‘Branded Jewellery UK’
          arising out of any breach by you of any provision of these terms and
          conditions, or arising out of any claim that you have breached any
          provision of these terms and conditions.
        </p>
        <h3 className="terms__subtitle">
          Breaches of these terms and conditions
        </h3>
        <p className="terms__main">
          Without prejudice to ‘Branded Jewellery UK’ other rights under these
          terms and conditions, if you breach these terms and conditions in any
          way, ‘Branded Jewellery UK’ may take such action as ‘Branded Jewellery
          UK’ deems appropriate to deal with the breach, including suspending
          your access to the website, prohibiting you from accessing the
          website, blocking computers using your IP address from accessing the
          website, contacting your internet service provider to request that
          they block your access to the website and/or bringing court
          proceedings against you.
        </p>
        <h3 className="terms__subtitle">Variation</h3>
        <p className="terms__main">
          ‘Branded Jewellery UK’ may revise these terms and conditions from
          time-to-time. Revised terms and conditions will apply to the use of
          this website from the date of the publication of the revised terms and
          conditions on this website. Please check this page regularly to ensure
          you are familiar with the current version.
        </p>
        <h3 className="terms__subtitle">Assignment</h3>
        <p className="terms__main">
          ‘Branded Jewellery UK’ may transfer, sub-contract or otherwise deal
          with ‘Branded Jewellery UK’ rights and/or obligations under these
          terms and conditions without notifying you or obtaining your consent.
          <br />
          <br /> You may not transfer, sub-contract or otherwise deal with your
          rights and/or obligations under these terms and conditions.
        </p>
        <h3 className="terms__subtitle">Severability</h3>
        <p className="terms__main">
          If a provision of these terms and conditions is determined by any
          court or other competent authority to be unlawful and/or
          unenforceable, the other provisions will continue in effect. If any
          unlawful and/or unenforceable provision would be lawful or enforceable
          if part of it were deleted, that part will be deemed to be deleted,
          and the rest of the provision will continue in effect.
        </p>
        <h3 className="terms__subtitle">Entire agreement</h3>
        <p className="terms__main">
          These terms and conditions constitute the entire agreement between you
          and ‘Branded Jewellery UK’ in relation to your use of this website,
          and supersede all previous agreements in respect of your use of this
          website.
        </p>
        <h3 className="terms__subtitle">Law and jurisdiction</h3>
        <p className="terms__main">
          These terms and conditions will be governed by and construed in
          accordance with GOVERNING LAW, and any disputes relating to these
          terms and conditions will be subject to the [non-]exclusive
          jurisdiction of the courts of JURISDICTION.
        </p>
        <h3 className="terms__subtitle">Registrations and authorisations</h3>
        <p className="terms__main">
          ‘Branded Jewellery UK Limited’ is registered with Companies House.
          Registration number is 09410866 <br />
          <br /> ‘Branded Jewellery UK’ is subject to AUTHORISATION SCHEME,
          which is supervised by a SUPERVISORY AUTHORITY. <br />
          <br />
          ‘Branded Jewellery UK’ is registered with Companies House as ‘Branded
          Jewellery UK Limited’. <br />
          <br /> ‘Branded Jewellery UK’ professional title is ‘Branded Jewellery
          UK Limited’. and it has been granted in the United Kingdom. <br />
          <br /> The full name of ‘Branded Jewellery UK’ is ‘Branded Jewellery
          UK Limited’ <br />
          <br /> ‘Branded Jewellery UK’ is registered as ‘Branded Jewellery UK
          Limited’ in London under registration number 09410866 <br />
          <br /> You can contact ‘Branded Jewellery UK’ by email to
          info@brandedjewelleryuk.com
        </p>
      </main>
      <Subscribe />
    </div>
  );
}

export default Terms;
