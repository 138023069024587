import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import ProductCard from "../../components/ProductCard/ProductCard";
import "../Shop/Shop.scss";

function Sale() {
  const [shopProducts, setShopProducts] = useState([]);

  useEffect(() => {
    const productsCollectionRef = collection(db, "products");
    const getProductData = async () => {
      const data = await getDocs(productsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const available = results.filter((item) => {
        return item.salePrice && item.available === "Is Available";
      });
      console.log(results);
      setShopProducts(available);
      document.title = "Shop - Branded Jewellery UK ";
    };
    getProductData();
  }, []);

  if (!shopProducts) {
    return <h1>Loading</h1>;
  }

  return (
    <div>
      <div className="shop">
        {shopProducts.map((product) => {
          return (
            <ProductCard data={product} key={product.id} isOnSale={true} />
          );
        })}
      </div>
    </div>
  );
}

export default Sale;
