import React from "react";
import "./DropdownItemMobile.scss";
import { Link } from "react-router-dom";

function DropdownItemMobile(props) {
  return (
    <Link to={`/shop/${props.name}`} className="dropdown-item-mobile">
      <h3 onClick={props.clickHandler} className="dropdown-item-mobile__item">
        {props.name}
      </h3>
    </Link>
  );
}

export default DropdownItemMobile;
