import React, { useState, useEffect } from "react";
import FavouriteProducts from "../../components/HomeComponents/FavouriteProducts/FavouriteProducts";
import SaleProducts from "../../components/HomeComponents/SaleProducts/SaleProducts";
import Subscribe from "../../components/Subscribe/Subscribe";
import Hero from "../../components/HomeComponents/Hero/Hero";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import "./Home.scss";
// import Sale from "../../assets/images/sale.png";

function Home() {
  const [prodData, setProdData] = useState(null);

  useEffect(() => {
    const productsCollectionRef = collection(db, "products");
    const getdata = async () => {
      const data = await getDocs(productsCollectionRef);
      let results = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const available = results.filter((item) => {
        return item.available === "Is Available";
      });
      setProdData(available);
      document.title = "Branded Jewellery UK";
    };
    getdata();
  }, []);

  if (!prodData) {
    return (
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#f6f4f0" }}
      ></div>
    );
  }

  return (
    <div className="home">
      <Hero />
      <FavouriteProducts data={prodData} />
      {/* <img src={Sale} alt="Sale" className="home__sale-banner" /> */}
      <SaleProducts data={prodData} />
      <Subscribe />
    </div>
  );
}

export default Home;
