import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageCarousel.scss";

function ImageCarousel(props) {
  return (
    <Carousel showArrows="true">
      <img src={props.data.mainImage} alt="main-jewel" key={"main-001"} />
      {props.data.images.map((image, id) => {
        return (
          <div key={id}>
            <img src={image} alt={props.data.title} />
          </div>
        );
      })}
    </Carousel>
  );
}

export default ImageCarousel;
