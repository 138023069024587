import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import "./Subscribe.scss";

function Subscribe() {
  const initialValues = {
    name: "",
    email: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const showForm = () => {
    if (isSubmit) {
      return "--hide";
    } else {
      return "--show";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, formValues, isSubmit]);

  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if (!values.name) {
      errors.name = `Name is required!`;
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (values.name && values.email && regex.test(values.email)) {
      setIsSubmit(true);
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors(validate(formValues));
    const newEmail = {
      // Add key value pairs for new email
      name: e.target.name.value,
      email: e.target.email.value,
    };

    const emailCollectionRef = collection(db, "customers");
    const createUser = async () => {
      await addDoc(emailCollectionRef, newEmail);
    };
    createUser();
    e.target.reset();
  };

  return (
    <div className="subscribe">
      <h2 className={`subscribe__title${showForm()}`}>
        Sign up for exclusive deals and discounts!
      </h2>
      <form onSubmit={handleSubmit} className={`subscribe__form${showForm()}`}>
        <div className="subscribe__input-container">
          <input
            type="text"
            name="name"
            onChange={handleChange}
            placeholder={formValues.name ? formValues.name : "Name"}
            value={formValues.name}
            className="subscribe__inputs"
          />
          <p>{formErrors.name}</p>

          <input
            type="text"
            name="email"
            onChange={handleChange}
            placeholder={formValues.email ? formValues.email : "Email"}
            value={formValues.email}
            className="subscribe__inputs"
          />
          <p>{formErrors.email}</p>
        </div>
        <button className="subscribe__button">Submit</button>
      </form>
      <h3 className={`subscribe__signed-up${showForm()}`}>Signed Up!</h3>
    </div>
  );
}

export default Subscribe;
