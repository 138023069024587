import React from "react";
import Header from "../../assets/images/about-hero.png";
import Subscribe from "../../components/Subscribe/Subscribe";
import { Link } from "react-router-dom";
import "./Payments.scss";

function Payments() {
  return (
    <div className="payments">
      <img
        src={Header}
        alt="Branded Jewellery Header"
        className="payments__hero"
      />
      <h1 className="payments__title">PAYMENTS, DELIVERY & RETURNS</h1>
      <main className="payments__content">
        <h2 className="payments__subtitle">PAYMENTS</h2>
        <p className="payments__main">
          We accept payments by Paypal or bank transfer. Orders will be
          dispatched once payment has been received or eligible for seller
          protection by Paypal. Payments by Paypal will only be shipped to the
          registered paypal address. <br />
          <br /> Shipments outside of the UK are subject to extra shipping
          charges and /or insurance. They will also be subject to import
          duties/taxes which are the responsibility of the buyer. Please contact
          us to organise your preferred method of payment.
        </p>
        <Link to="/contact" className="payments__contact">
          <button>Contact Us</button>
        </Link>
        <h2 className="payments__subtitle">RETURNS</h2>
        <p className="payments__main">
          You may return any item from our collection within 7 days of delivery
          for a full refund provided it is in the same condition as it was
          received. We will also pay the return shipping costs if the return is
          a result of our error (you received an incorrect or defective item,
          etc.). <br /> If you need to return an item, contact us with your
          intention to return it. <br />
          <br /> The payment will be refunded by the same method it was paid.
          Returns will be taken back within 7 days only if they have remained
          un-worn and in the exact same condition as sold. Please note that
          items showing signs of wear, or having been altered, re-sized or
          damaged in any way cannot be accepted for return. Evaluation of the
          condition will be at the discretion of our highly trained staff. We
          reserve the right to charge restocking fees if any of these conditions
          are not met. This will be within reason, but will be determined by us
          by the extent to which these conditions were broken or any damage that
          has been caused to the returned item. <br />
          <br /> Returns of orders will be confirmed only where you have
          returned the item in its original condition, unworn and together with
          its original packaging and certificate where applicable. Non-return of
          an original certificate will be charged and you may be charged an
          additional cost for branded packaging and any other collateral
          material. <br />
          <br /> Note: ‘Branded Jewellery UK’ will not be responsible for loss
          or damage of return shipments. We cannot be held liable for any loss
          or damage you may suffer as a result of your return, late delivery or
          cancellation of your order, so we advise that you retain all proof of
          posting and documentation. If you have any questions regarding returns
          please email info@brandedjewelleryuk.com.
        </p>
        <h2 className="payments__subtitle">
          WARRANTY / 100% AUTHENTICITY GUARANTEE
        </h2>
        <p className="payments__main">
          Items listed as Pre-Owned: Where possible the original paperwork will
          accompany the item, but in most cases will be out of manufacturer's
          warranty. Most jewellery items due to the nature of the items are
          excluded from a full warranty as they will have been fully inspected
          for quality and any defects will be mentioned in the description. Any
          discrepancies should be declared within the 7 day period from receipt.
          We will extend a limited warranty to cover any minor incidentals or
          manufacturing defects at our discretion. Feel free to enquire what
          warranty comes with your specific item. <br />
          <br /> Please enquire on specific warranties applying to watches.{" "}
          <br /> <br />
          NB Branded Jewellery UK Limited is not an authorized dealer and has no
          affiliation to the manufacturers of the items offered for sale. All
          brand names and trademarks are the property of their respective owners
          and are used for identification purposes only. All items offered by
          Branded Jewellery UK Limited are guaranteed to be 100% genuine,
          limited to a 12 month period. <br />
          <br />
          If you are not entirely satisfied with your purchase, we offer you a 7
          day money back guarantee.
        </p>
        <p className="payments__main">
          For further information, please visit the link below.
        </p>
        <Link to="/terms&conditions" className="payments__contact">
          <button>Terms & Conditions</button>
        </Link>
      </main>
      <Subscribe />
    </div>
  );
}

export default Payments;
